import React, { useState } from 'react';
import MainHeader from '../components/MainHeader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input'
import Sidebar from '../components/Sidebar';
import Sidedefault from '../components/Sidedefault';
const AddContact = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [cntData, setCntData] = useState({
        account_id: "",
        phone: "",
        email: "",
        last_name: "",
        first_name: "",
        routing_number: ""
    });
    const [errors, setErrors] = useState({});
    const isAlpha = (value) => /^[A-Za-z\s]*$/.test(value);
    const isNumeric = (value) => /^\d+$/.test(value);
    const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const handleChange = (name, value) => {

        setCntData({ ...cntData, [name]: value });

        if ((name === "first_name" || name === "last_name") && !isAlpha(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Only letters are allowed"
            }));
            return;
        }

        if (name === "phone") {

            if (value.length != 12) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: "Phone number should be 10 digits"
                }));
                return;
            }
        }

        if (name === "email" && !isValidEmail(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Invalid email format"
            }));
            return;
        }
        if ((name === "account_id") && value?.length != 16) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid account number"
            }));
            return;
        }
        if ((name === "routing_number") && value?.length != 9) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "Invalid routing number"
            }));
            return;
        }


        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ""
        }));

    };


    const handleSubmit = (e) => {
        e.preventDefault();
        Object.keys(errors).forEach(key => {
            if (errors[key] === '') {
                delete errors[key];
            }
        });
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            console.log("eroors", errors);
            return;
        } else {

            let updatedCntData = { ...cntData, phone: cntData?.phone?.slice(2) }
            navigate(`/contact-address/${params?.acc_id}`, { state: { data: updatedCntData, uuid: location?.state?.uuid } });
        }
    };

    return (
        <>
            {/* <MainHeader /> */}
            <div className="s-layout">
                <Sidedefault />
                <main className="s-layout__content">
                    <div className="main-page">
                        <div className="row ">
                            <div className="col-lg-8 col-md-10">
                                <div className="card login-part">
                                    <div className="authincation-content">
                                        <div className="row no-gutters">
                                            <div className="col-xl-12">
                                                <div className="auth-form">
                                                    <h4 className="mb-4">Add Contacts</h4>
                                                    <form className="mt-4" onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">First Name or Business Name</label>
                                                            <input
                                                                onChange={(e) => handleChange("first_name", e.target.value)}
                                                                name='first_name'
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="First Name or Business Name"
                                                            />
                                                            {errors.first_name && <small className="text-danger error">{errors.first_name}</small>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Last Name</label>
                                                            <input
                                                                onChange={(e) => handleChange("last_name", e.target.value)}
                                                                name='last_name'
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Last Name"
                                                            />
                                                            {errors.last_name && <small className="text-danger error">{errors.last_name}</small>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Mobile number</label>

                                                            <PhoneInput
                                                                placeholder="Mobile number"
                                                                country="US"

                                                                className="form-control"
                                                                // value={value}
                                                                onChange={(e) => { handleChange("phone", e) }} />
                                                            {errors.phone && <small className="text-danger error">{errors.phone}</small>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Email</label>
                                                            <input
                                                                onChange={(e) => handleChange("email", e.target.value)}
                                                                name='email'
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Email"
                                                            />
                                                            {errors.email && <small className="text-danger error">{errors.email}</small>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Account Number</label>
                                                            <input
                                                                onChange={(e) => handleChange("account_id", e.target.value)}
                                                                name='account_id'
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Account Number"
                                                            />
                                                            {errors.account_id && <small className="text-danger error">{errors.account_id}</small>}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label">Routing Number</label>
                                                            <input
                                                                onChange={(e) => handleChange("routing_number", e.target.value)}
                                                                name='routing_number'
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Routing Number"
                                                            />
                                                            {errors.routing_number && <small className="text-danger error">{errors.routing_number}</small>}
                                                        </div>
                                                        <div className="create-account mt-5">
                                                            <button>Create</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default AddContact;

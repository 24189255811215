import React from 'react'
import profile from "../assets/images/no-user.svg"
import TransactionsHelper from '../hooks/TransactionsHelper.tsx'
const TransactionTable = ({
    item, accountId
}) => {

    function toCamelCase(str) {
        return str
            .toLowerCase()
            .replace(/\b\w/g, (match, index) =>
                index === 0 ? match.toUpperCase() : match
            );
    }

    function formatDateWithWeekday(isoString) {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const date = new Date(isoString); // Parse the ISO date string

        let day = days[date.getDay()];
        let month = months[date.getMonth()];
        let dayOfMonth = date.getDate();
        let year = date.getFullYear();

        // Capitalize part of the day name (e.g., "Tuesday" -> "TuesDay")
        day = day.slice(0, 4) + day.slice(4).toUpperCase();

        return `${toCamelCase(day)}, ${month} ${dayOfMonth}, ${year}`;
    }
    return (
        <div className="trans-list">
            <div className="trans-usr">
                <img src={profile} alt='user' />
                <text>{TransactionsHelper.getLabel(item?.attributes, accountId)}<small>{formatDateWithWeekday(item?.attributes?.created_at)}</small></text>
            </div>
            <span className={`${item?.attributes?.debitCredit == "debit" ? "ach-out" : "ach-in"}`}>{item?.attributes?.debitCredit == "debit" ? "-" : "+"}${Number(item?.attributes?.amount || 0).toFixed(2)}<small>{toCamelCase(item?.attributes?.status)}, <strong>{item?.attributes?.txn_type?.toLowerCase() == "achincoming" ? "ACH IN" : (item?.attributes?.txn_type == "CORE" ? "Intrabank" : "ACH out")}</strong></small>
            </span>
        </div>

    )
}
export default TransactionTable

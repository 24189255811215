import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import copyTextToClipboard from '../hooks/copyTextToClipboard';
import axios from 'axios';
import { formatCurrency } from '../hooks/dollarFormatter';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import { Bars } from 'react-loader-spinner';
import Sidebar from '../components/Sidebar';
import Sidedefault from '../components/Sidedefault';


const FundThisAccount = () => {
    const location = useLocation();
    const params = useParams();


    // console.log("fund this accont param", params);

    const [detail, setDetail] = useState([]);
    const navigate = useNavigate();
    // console.log(location?.state);
    const [loading, setLoading] = useState(false)
    const [fundData, setFundData] = useState({

        amount: "",
        // account_number: "",
        account_id: ""
    });
    const [errors, setErrors] = useState({
        account_id: false,
        amount: false
    })

    const { data: allAccountsData, dataLoading: allAccountsDataLoading } = useFetch(`${endPoints.ALL_ACCOUNTS}`);
    // console.log("allAccountsData", allAccountsData);
    const accountDetail = () => {

        const ssdd = allAccountsData?.data?.filter((item) => {
            if (item?.attributes?.uuid == params?.acc_label?.split("_")[0]) {
                return item
            }
        });

        setDetail(ssdd)
    }
    useEffect(() => {
        if (allAccountsData) {
            accountDetail();
        }
    }, [allAccountsData,])
    // console.log("ssdd",detail)

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (fundData.account_id == "" || fundData.amount.trim() == "") {
        //     if (fundData.amount.trim() == "") {
        //         setErrors({ ...errors, amount: true });
        //     }
        //     if (fundData.account_id == "") {
        //         setErrors({ ...errors, account_id: true });
        //     }
        //     return
        // }
        let validationErrors = {};

        if (!fundData?.account_id) validationErrors.account_id = "Required";
        if (!fundData?.amount?.trim()) validationErrors.amount = "Required";


        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {

            setLoading(true);
            const label = allAccountsData?.data?.filter((item) => {
                if (item?.attributes?.uuid == fundData?.account_id) {
                    return item;
                }
            });
            const findNikname = allAccountsData?.data?.filter((item) => {
                if (params?.acc_label?.split("_")[0] == item?.attributes?.uuid) {
                    return item
                }
            });
            const data = {
                "label": `From ${label[0]?.attributes?.label} to  ${detail[0]?.attributes?.label}`,
                "account_id": `${fundData?.account_id}`,
                "account_number": `${findNikname[0]?.attributes?.accountNickName}`,
                "amount": `${fundData?.amount}`,
                "purpose": "Pull Funds",
                "sent_from": `${label[0]?.attributes?.label}`
            }

            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MAKE_PAY_VIA_CORE}`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp?.status == 200) {
                setLoading(false);
                // navigate("/view-account", { state: { uuid: params?.acc_label?.split("_")[0] } })
                navigate(`/${params?.name}/accounts/${params?.acc_label}`);

                toast.success("Fund Transferred Successfully");
            }

        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
            console.log(error);
        }
    }
    return (
        <>
            {/* <MainHeader /> */}
            {!allAccountsDataLoading && (
                <div className="s-layout">
                    <Sidedefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-3">Fund This Account</h4>
                                            </div>
                                        </div>
                                        <div className="card-body pt-2">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <h5>Push Funds to SkyFi account</h5>
                                                    <ul className="detail-text">
                                                        <li>
                                                            <span>
                                                                Account Number
                                                                <strong>
                                                                    {detail[0]?.attributes?.finxact_party_account_num}
                                                                    <button className='ms-2' onClick={() => copyTextToClipboard(detail[0]?.attributes?.finxact_party_account_num)}>

                                                                        <svg
                                                                            className="focus"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path
                                                                                fill="currentColor"
                                                                                fill-rule="evenodd"
                                                                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </strong>
                                                            </span>
                                                            <span>
                                                                Routing Number
                                                                <strong className=''>
                                                                    {detail[0]?.attributes?.routing_number}
                                                                    <button className='ms-2' onClick={() => copyTextToClipboard(detail[0]?.attributes?.routing_number)}>
                                                                        <svg
                                                                            className="focus"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 16 16"

                                                                        >
                                                                            <path
                                                                                fill="currentColor"
                                                                                fill-rule="evenodd"
                                                                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </strong>
                                                            </span>
                                                            <span>
                                                                Type <strong>{detail[0]?.attributes?.account_type}</strong>
                                                            </span>
                                                            <span>
                                                                Sponsor Bank <strong>Stearns</strong>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-xl-12 mb-3">
                                                    <ul className="tab-links">
                                                        <li>
                                                            <a className="cursor-pointer" onClick={() => { navigate(`/${params?.name}/${params?.acc_label}/ach-pay-in`, { state: location?.state }) }}>
                                                                <span className="frst">
                                                                    Pull
                                                                    <small>
                                                                        Pull funds from your account to another bank{" "}
                                                                    </small>
                                                                </span>{" "}

                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                {allAccountsData?.data?.length > 1 && (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="col-xl-12">
                                                            <h5>Push Funds to SkyFi account</h5>
                                                            <select
                                                                className="form-control cursor-pointer mb-3"
                                                                aria-label="Default select example"
                                                                name="account_id"
                                                                id=""
                                                                onChange={(e) => {
                                                                    setFundData({ ...fundData, account_id: e.target.value });
                                                                    setErrors({ ...errors, account_id: false });
                                                                }}
                                                            >
                                                                <option value="">
                                                                    {allAccountsDataLoading ? "Fetching Accounts..." : "Select an account"}
                                                                </option>
                                                                {allAccountsData?.data?.map((item, id) => {
                                                                    if (item?.attributes?.uuid !== params?.acc_label?.split("_")[0]) {
                                                                        return (
                                                                            <option value={item?.attributes?.uuid} key={id}>
                                                                                {item?.attributes?.label} {formatCurrency(item?.attributes?.remaining_balance ?? 0)}
                                                                            </option>
                                                                        );
                                                                    }
                                                                })}
                                                            </select>
                                                            {errors?.account_id && <div className="text-danger">Required</div>}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <div className="pull-funds-inpt">
                                                                <div>
                                                                    <CurrencyInput
                                                                        className="form-control"
                                                                        name="amount"
                                                                        prefix="$"
                                                                        placeholder="$1.00"
                                                                        defaultValue={fundData?.amount}
                                                                        decimalsLimit={2}
                                                                        onValueChange={(value, name, values) => {
                                                                            setFundData({ ...fundData, amount: value });
                                                                            setErrors({ ...errors, amount: false });
                                                                        }}
                                                                    />
                                                                    {errors?.amount && <div className="text-danger">Required</div>}
                                                                </div>
                                                                <button disabled={loading}>{loading ? "Transferring..." : "Pull Funds"}</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={allAccountsDataLoading}
            />
        </>
    );

}

export default FundThisAccount
import React from 'react'
import MainHeader from '../components/MainHeader'

const Settings = () => {
  return (
   <>
   <MainHeader />
          <div className="content-body default-height">
              {/* row */}
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-xl-11 col-xxl-10 m-auto main-page">
                          <div className="row">
                              <div className="col-xl-12">
                                  <div className="card plan-list">
                                      <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                          <div className="me-auto pe-3 mb-3">
                                              <h4 className="text-black fs-26 mb-3">Settings</h4>
                                          </div>
                                      </div>
                                      <div className="card-body  pt-2">
                                          <div className="row">
                                              <div className="col-xl-12">
                                                  <h5>Notifications </h5>
                                                  <ul className="tab-links setting-toggle">
                                                      <li>
                                                          <a className="cursor-pointer">
                                                              ACH Transaction{" "}
                                                              <div className="checkbox">
                                                                  <input type="checkbox" id="switch" />
                                                                  <label htmlFor="switch" />
                                                              </div>
                                                          </a>
                                                      </li>
                                                      <li>
                                                          <a className="cursor-pointer">
                                                              Card Transaction{" "}
                                                              <div className="checkbox">
                                                                  <input type="checkbox" id="switch1" />
                                                                  <label htmlFor="switch1" />
                                                              </div>
                                                          </a>
                                                      </li>
                                                      <li>
                                                          <a className="cursor-pointer">
                                                              Intrabank Transaction{" "}
                                                              <div className="checkbox">
                                                                  <input type="checkbox" id="switch2" />
                                                                  <label htmlFor="switch2" />
                                                              </div>
                                                          </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

   </>
  )
}

export default Settings
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumb = ({ routes }) => {
    return (
        <div className=" d-flex justify-content-end pe-5 pt-3">

        <nav aria-label="breadcrumb ">
            <ol className="breadcrumb">
                {routes.map((route, index) => (
                    <li
                    key={index}
                        className={`breadcrumb-item ${index === routes.length - 1 ? 'active' : ''}`}
                        aria-current={index === routes.length - 1 ? 'page' : undefined}
                    >
                        {index === routes.length - 1 ? (
                            route.name
                        ) : (
                            <Link to={route.path}>{route.name}</Link>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
                </div>
    );
};

Breadcrumb.propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        })
    ).isRequired
};

export default Breadcrumb;

import { toast } from "react-toastify";

const copyTextToClipboard = (text) => {

    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          return  toast.success('Text copied !');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    } else {

        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
         return   toast.success('Text copied !');

        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }
};

export default copyTextToClipboard
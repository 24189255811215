import React, { createContext, useState } from 'react';
// Create a context
const MyContext = createContext();
const MyProvider = ({ children }) => {
    const [updatebusiness, setUpdateBusiness] = useState([]);
    const [phone, setPhone] = useState({
        phone: "",
        countryCode: "",
    });
    
    return (
        <MyContext.Provider value={{ updatebusiness, setUpdateBusiness, phone, setPhone }}>
            {children}
        </MyContext.Provider>
    );
};
export { MyContext, MyProvider };
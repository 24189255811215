import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { formatterNew } from '../hooks/dollarFormatter';
import { Bars } from 'react-loader-spinner';

const CertificateOfDeposit = () => {
    const [fetchDeposit, setFetchDeposit] = useState(0);
    const { data: me, dataLoading: meLoading } = useFetch(`${endPoints.ME}`);

    const { data: deposits, dataLoading } = useFetch(`${endPoints.CERTIFICATE_OF_DEPOSIT}/${me?.uuid}/deposits`, fetchDeposit);
    useEffect(() => {
        if (me) {
            setFetchDeposit(fetchDeposit + 1);
        }
    }, [me])
    const loading = () => {
        if (dataLoading || meLoading) {
            return true
        }
        else return false
    }
    return (
        <>
            <MainHeader />
            {!loading() && <div className="content-body default-height">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-11 col-xxl-10 m-auto main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3">
                                                <h4 className="text-black fs-26 mb-0">Certificate</h4>
                                            </div>
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="row">
                                                {deposits?.data.length > 0 && deposits?.data?.map((item) => {
                                                    return (
                                                        <div className="col-md-6 mb-3" key={item?.id}>
                                                            <div className="add-acts certificate-list">
                                                                <div className="lists">
                                                                    <span className="frst">
                                                                        <small>{item?.attributes?.cd_account_label}</small>
                                                                        {formatterNew?.format(item?.attributes?.cd_account_balance ?? 0)}
                                                                    </span>
                                                                    <span>ACC# {item?.attributes?.cd_account_number}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                                }

                                                {/* <div className="col-md-12">
                                                  <nav aria-label="..." className="d-flex mt-3 mb-3">
                                                      <ul className="pagination pagination-circle m-auto">
                                                          <li className="page-item">
                                                              <a className="page-link">Prev</a>
                                                          </li>
                                                          <li className="page-item">
                                                              <a className="page-link" href="#">
                                                                  1
                                                              </a>
                                                          </li>
                                                          <li className="page-item active" aria-current="page">
                                                              <a className="page-link" href="#">
                                                                  2 <span className="visually-hidden">(current)</span>
                                                              </a>
                                                          </li>
                                                          <li className="page-item">
                                                              <a className="page-link" href="#">
                                                                  3
                                                              </a>
                                                          </li>
                                                          <li className="page-item">
                                                              <a className="page-link" href="#">
                                                                  Next
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </nav>
                                              </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading()}
            />
        </>
    )
}

export default CertificateOfDeposit
import axios from "axios";
import { useEffect, useState } from "react";
import baseUrl from "../baseUrl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useMutation = (url) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

  const mutate = async (payload) => {
    try {
      const user = localStorage.getItem("skyfi");
      const tk = JSON.parse(user);
      if (!tk) {
        navigate("/login");
        return;
      }
      setLoading(true);
      const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${tk.access_token}`,
        },
      });

      if (resp?.status == 200) {
        setData(resp.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error);
      if (error.response.status == 401) {
        localStorage.clear();
        navigate("/login");
        return;
      }
      console.log("error", error);
    }
  };
  return { data, loading, mutate };
};

export default useMutation;

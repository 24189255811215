import React from 'react'

import { useNavigate, useParams } from 'react-router-dom';

const AcccountReady = (props) => {
   
    const navigate=useNavigate()
    const b_id = useParams();
    const handleLink=()=>{
        navigate(`/${b_id?.b_name}/accounts/`);
    }
  return (
   <>
          <div className="authincation content-body default-height">
              <div className="container h-100">
                  <div className="row justify-content-center h-100 align-items-center">
                      <div className="col-xl-11 col-xxl-10 m-auto main-page">
                          <div className="row justify-content-center h-100 align-items-center">
                              <div className="col-lg-10 col-md-10">
                                  <div className="card">
                                      <div className="account-rdy">
                                          <h4>ACH Payment</h4>
                                          <p>
                                              Your Pineeee account is now ready to use.
                                              <br /> See the account details below.
                                          </p>
                                          <ul>
                                              <li>
                                                  <small>Account Number</small>
                                                  <strong>{props?.data?.finxact_party_account_num}</strong>
                                              </li>
                                              <li>
                                                  <small>Routing number</small>
                                                  <strong>{props?.data?.routing_number}</strong>
                                              </li>
                                          </ul>
                                          <div className="ac-ready-btn">
                                              <button type="submit" className="btn" onClick={() => {  handleLink()}}>
                                            
                                                 Proceed
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

   </>
  )
}

export default AcccountReady


    
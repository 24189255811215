export const getFirstDateOfMonth = () => {
  const date = new Date();
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);

  const month = String(firstDate.getMonth() + 1).padStart(2, "0"); 
  const day = String(firstDate.getDate()).padStart(2, "0");
  const year = firstDate.getFullYear();

  return `${year}-${month}-${day}`;
};


export const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};


import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import endPoints from '../constants';
import { toast } from 'react-toastify';
import AcccountReady from '../components/AcccountReady';
import useFetch from '../hooks/useFetch';
import { Bars } from 'react-loader-spinner';
import usePost from '../hooks/usePost';
import { useAuth } from '../components/AuthContext';
import Sidebar from '../components/Sidebar';
import Sidedefault from '../components/Sidedefault';

const AddingAccount = () => {

    const [accLabel, setAccLabel] = useState("");
    const slug = useParams();
    const navigate = useNavigate();
    const { userRole } = useAuth();
    const [accLabelError, setAccLabelError] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAccountReady, setShowAccountReady] = useState(false);
    const [accntRedyData, setAccntRedyData] = useState(null);
    const [filterData, setFilterData] = useState(null)
    let busines_id = slug?.b_name?.split("_")[0];

    const { data, dataLoading } = useFetch(endPoints.RELATIONSHIP_TYPES);
    const { data: business, dataLoading: businessLoading } = useFetch(`${endPoints.GET_BUSINESS_ACCOUNT}/${busines_id}`);


    const comp_loading = () => {
        if (dataLoading || businessLoading) {
            return true
        }
        else return false
    }
    useEffect(() => {
        if (data && business) {
            let ftr = data?.find((item) => (business?.entity_type == item?.description || business?.entity_type == item?.type));
            setFilterData(ftr)
        }

    }, [data, business])



    const { data: postData, error, loading: postLoading, post } = usePost(endPoints.LINK_ACCOUNT);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (accLabel.trim() === "") {
            setAccLabelError(true);
            return;
        }

        try {
            setLoading(true);

            let data = {
                label: `${accLabel}`,
                acceptedTerms: `${acceptedTerms}`,
                business_id: `${busines_id}`,
                entity_type: `${filterData[0]?.type ?? filterData?.type}`,
                entity_type_desc: `${filterData[0]?.description ?? filterData?.description}`,
                relType: `${filterData[0]?.relType ?? filterData?.relType}`
            };

            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}${endPoints.LINK_ACCOUNT}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${tk.access_token}`,
                    },
                }
            );

            if (response?.status == 200) {

                // toast.success("Account Created");
                toast.success('Account linked successfully!');
                setAccLabel("");
                setAccntRedyData(response?.data?.data?.attributes);
                setShowAccountReady(true);
                setAcceptedTerms(false);

            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
            console.log("error", error);
        }
    };
    return (
        <>
            {/* <MainHeader /> */}
            {!showAccountReady && !comp_loading() &&
                <div className="s-layout">
                    <Sidedefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row ">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4">Add Account</h4>
                                                        <form onSubmit={handleSubmit} className="mt-4">
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">

                                                                    Account Label
                                                                </label>
                                                                <input
                                                                    value={accLabel}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Account Label"
                                                                    onChange={(e) => { setAccLabel(e.target.value); setAccLabelError(false) }}
                                                                />
                                                                {accLabelError && <small className="text-danger">Account Label Requred!</small>}
                                                            </div>
                                                            <div className="i-agree">
                                                                <input
                                                                    type="checkbox"
                                                                    id="agree"
                                                                    defaultValue="agree"
                                                                    checked={acceptedTerms}
                                                                    onChange={(e) => { setAcceptedTerms(e.target.checked) }}

                                                                />
                                                                <label htmlFor="agree">
                                                                    By opening an account, I agree to all privacy
                                                                    policies, agreements and terms and conditions
                                                                </label>
                                                            </div>
                                                            <div className="sky-account">
                                                                <a className="cursor-pointer">SkyFi Account</a>
                                                            </div>
                                                            <div className="create-account mt-5">
                                                                <button disabled={loading}>{loading ? "Loading..." : "Proceed"}</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>}

            {showAccountReady && <AcccountReady data={accntRedyData} business_id={busines_id} />}

            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={comp_loading()}
            />
        </>
    )
}

export default AddingAccount
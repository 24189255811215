// // ProtectedRoute.js
// import React, { useEffect } from 'react';
// import { Navigate, useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';

// const ProtectedRoute = ({ roles, element }) => {
//     const { userRole } = useAuth();
//     const navigate=useNavigate();
//     useEffect(() => {
//         const user = localStorage.getItem("skyfi");
//         const detail = localStorage.getItem("user");
//         const role = localStorage.getItem("skyrole");
//         if (!user || !detail || !role) {
//             navigate("/login");
//         }
//     }, []);

//     // Check if the user's role is in the list of allowed roles
//     if (roles.includes(userRole)) {
//         return element;
//     }

//     // If the role is not authorized, redirect to the home page or a 404 page
//     return <Navigate to="/" />;
// };

// export default ProtectedRoute;



import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Bars } from 'react-loader-spinner';

const ProtectedRoute = ({ roles, element }) => {
    const { userRole, loading } = useAuth();
    // Show a loading indicator while checking the user role
    if (loading) {
        return <div>
            <Bars 
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading}
            />
        </div>;
    }
    // Check if the user's role is in the list of allowed roles
    if (roles.includes(userRole) && !loading) {
        return element;
    }
    // If the role is not authorized, redirect to the home page or a 404 page
    return <Navigate to="/" />;
};

export default ProtectedRoute;

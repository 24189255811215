import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { decryptData } from '../hooks/encryptionUtils';

export const Protected = (props) => {
  const { Component } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const [history, setHistory] = useState([]);
  const handleDecrypt = () => {
    let user_role = localStorage.getItem("user");

    if (user_role) {
      try {
        const decrypted = decryptData(user_role);

      } catch (error) {
        localStorage.clear();
        navigate("/login");
      }
    }
    else {
      localStorage.clear();
      navigate("/login");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    handleDecrypt();
  }, [pathname]);


  return <Component />
}

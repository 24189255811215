import React from 'react'
import logo from "../assets/images/logo.png"

const SimpleHeader = () => {
  return (
    <header className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="dashboard_bar">
                <img src={logo} />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default SimpleHeader
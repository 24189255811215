import React from 'react'
import MainHeader from '../components/MainHeader'
import useFetch from '../hooks/useFetch';
import { useNavigate, useParams } from 'react-router-dom';
import endPoints from '../constants';
import { formatterNew } from '../hooks/dollarFormatter';
import { Bars } from 'react-loader-spinner';
import { useAuth } from '../components/AuthContext';
import Breadcrumb from '../components/Breadcrumb';
import Sidedefault from '../components/Sidedefault';
import TransactionTable from '../components/TransactionTable';
import profile from "../assets/images/no-user.svg"

const ViewAccount = () => {

    const { userRole } = useAuth();
    const params = useParams();
    const navigate = useNavigate();
    let page = 1;
    let per_page = 3;
    const { data: allAccountsData, dataLoading: allAccountsDataLoading } = useFetch(`${endPoints.ALL_ACCOUNTS}/${params?.acc_label?.split("_")[0]}`);
    const { data: contacts, dataLoading: contactDataLoading } = useFetch(`${endPoints.CONTACTS}?account_id=${params?.acc_label?.split("_")[0]}`);
    const { data: transaction, dataLoading: transactionDataLoading } = useFetch(`${endPoints.BANK_TRANSACTION}?page=${page}&per_page=${per_page}&account_id=${params?.acc_label?.split("_")[0]}`);
    const loading = () => {
        if (transactionDataLoading || contactDataLoading || allAccountsDataLoading) {
            return true
        }
        else return false
    }

    const showAccountDetails = () => {
        navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/account-details`, { state: { uuid: params?.acc_label?.split("_")[0], business_uuid: allAccountsData?.data?.attributes?.business_uuid } })
    }

    const handleCards = () => {
        navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards`, { state: { uuid: params?.acc_label?.split("_")[0], business_uuid: allAccountsData?.data?.attributes?.business_uuid } })
    }
    // breadcrumb routes
    const routes = [
        { name: 'Home', path: '/' },
        { name: `Business Accounts`, path: `/${params?.name}/accounts` },
        { name: `Account Details`, path: '' },
    ];

    function splitDecimal(value) {
        // Convert value to string to ensure decimal point can be processed
        const strValue = value.toString();

        // Split the string by the decimal point
        const parts = strValue.split('.');

        // Return an object with the value before and after the decimal point
        return {
            beforeDot: parts[0],
            afterDot: parts[1] || '0' // Handle case where there's no decimal part
        };
    }

    const balance = allAccountsData?.data?.attributes?.remaining_balance.toString().split(".");
    return (
        <>
            {/* <MainHeader /> */}
            {!loading() && <div className="s-layout">
                <Sidedefault />
                <main className="s-layout__content">
                    <div className="main-page">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card plan-list">
                                    {/* <Breadcrumb routes={routes} /> */}

                                    <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                        <div className="me-auto pe-3 d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px", color: "38AFFF" }} width="30" height="40" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M15.71 12.71a6 6 0 1 0-7.42 0a10 10 0 0 0-6.22 8.18a1 1 0 0 0 2 .22a8 8 0 0 1 15.9 0a1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1a10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 1 1 4-4a4 4 0 0 1-4 4Z" />
                                            </svg>
                                            <h5 className="text-black fs-26 mb-0">Account Details</h5>
                                        </div>

                                        <div className="add-card">
                                            <span>Account#{allAccountsData?.data?.attributes?.finxact_posn_account_number}</span>
                                            {/* <a className='cursor-pointer' onClick={handleCards}>
                                                <svg
                                                    width={23}
                                                    height={19}
                                                    viewBox="0 0 23 19"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_274_1471)">
                                                        <path
                                                            d="M1.81018 16.1337C1.29459 16.1337 0.864181 15.9557 0.518957 15.5997C0.172986 15.243 0 14.7988 0 14.2672V1.8201C0 1.28847 0.172986 0.844672 0.518957 0.48871C0.864181 0.131979 1.29459 -0.0463867 1.81018 -0.0463867H18.3653C18.8808 -0.0463867 19.3113 0.131979 19.6565 0.48871C20.0025 0.844672 20.1754 1.28847 20.1754 1.8201V7.10982H1.12086V14.2672C1.12086 14.4444 1.19259 14.6073 1.33606 14.756C1.48028 14.904 1.63832 14.9779 1.81018 14.9779H11.4238V16.1337H1.81018ZM1.12086 4.35459H19.0546V1.8201C19.0546 1.64289 18.9828 1.47993 18.8394 1.33123C18.6952 1.1833 18.5371 1.10933 18.3653 1.10933H1.81018C1.63832 1.10933 1.48028 1.1833 1.33606 1.33123C1.19259 1.47993 1.12086 1.64289 1.12086 1.8201V4.35459ZM18.4942 19.023V15.5558H15.1316V14.4001H18.4942V10.9329H19.615V14.4001H22.9776V15.5558H19.615V19.023H18.4942Z"
                                                            fill="black"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_274_1471">
                                                            <rect width={23} height={19} fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                View Cards
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="card-body busi-account  pt-2">
                                        <div className="row">
                                            <div className="col-xl-12 mb-3">
                                                <div className="add-acts">
                                                    <div className="lists">
                                                        <span className="frst text-capitalize  cursor-pointer" onClick={() => { showAccountDetails() }}>
                                                            {/* <small>{allAccountsData?.data?.attributes?.label}</small> */}
                                                            <h5>Total Balance</h5>
                                                            {allAccountsData?.data?.attributes?.remaining_balance != null ? (
                                                                <div className='ttl-blnc'>
                                                                    <span>$</span>
                                                                    <span>{balance[0]}</span>
                                                                    <small>.{balance[1] || '00'}</small>
                                                                </div>
                                                            ) : (
                                                                <div className='ttl-blnc'>
                                                                    <span>$</span>
                                                                    <span>0</span>
                                                                    <small>.00</small>
                                                                </div>
                                                            )}
                                                        </span>
                                                        {userRole != "view_account" &&
                                                            <div className="col-xl-12 pt-2">
                                                                {/* <h5>Move Funds</h5> */}
                                                                <div className="row tab-links">
                                                                    <div className="col-md-6">
                                                                        <a className='cursor-pointer' style={{ color: "38AFFF" }} onClick={() => { navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/fund-this-account`, { state: { uuid: params?.acc_label?.split("_")[0] } }) }}>
                                                                            Fund this Account
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                                <path fill="currentColor" d="m4.641 12.5l2.873 2.704a.75.75 0 0 1-1.028 1.092l-4.25-4a.75.75 0 0 1 0-1.092l4.25-4a.75.75 0 1 1 1.028 1.092L4.641 11H14.75a1.75 1.75 0 0 0 1.75-1.75v-4.5a.75.75 0 0 1 1.5 0v4.5a3.25 3.25 0 0 1-3.25 3.25H4.641Z" />
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <a className="cursor-pointer" onClick={() => { navigate(`/move-funds/${params?.name?.split("_")[0]}/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0], acc_name: allAccountsData?.data?.attributes?.label, acc_num: allAccountsData?.data?.attributes?.finxact_party_account_num } }) }}>
                                                                            Send Funds Out
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                                                <path fill="currentColor" d="M13.22 19.03a.75.75 0 0 1 0-1.06L18.19 13H3.75a.75.75 0 0 1 0-1.5h14.44l-4.97-4.97a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l6.25 6.25a.75.75 0 0 1 0 1.06l-6.25 6.25a.75.75 0 0 1-1.06 0Z" />
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-xl-12 mt-4">
                                                <div className="view-all-row">
                                                    <h5>Contacts</h5>
                                                    <a className='cursor-pointer' onClick={() => { navigate(`/view-contacts/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0], } }) }}>
                                                        View All

                                                        <svg
                                                            width={12}
                                                            height={12}
                                                            viewBox="0 0 12 12"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M10.832 6.30811H0.832031"
                                                                stroke="black"
                                                                strokeWidth="1.375"
                                                                strokeMiterlimit={10}
                                                                strokeLinecap="round"
                                                            />
                                                            <path
                                                                d="M6.43607 1.52051L10.5442 5.62738C10.6341 5.71632 10.7056 5.82221 10.7543 5.93893C10.803 6.05566 10.8281 6.18089 10.8281 6.30738C10.8281 6.43387 10.803 6.55911 10.7543 6.67583C10.7056 6.79256 10.6341 6.89845 10.5442 6.98738L6.43607 11.0955"
                                                                stroke="black"
                                                                strokeWidth="1.375"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                            {contacts?.data && contacts?.data?.map((item, id) => {
                                                console.log("contacts ", item);
                                                if (id < 3)
                                                    return (
                                                        <div className="col-md-4 col-sm-6" key={id} onClick={() => { navigate(`/contact-details/${params?.acc_label}/${item?.attributes?.uuid}`, { state: { contactId: item?.attributes?.uuid, uuid: params?.acc_label?.split("_")[0], details: item } }) }}>
                                                            <a className="cursor-pointer">
                                                                <div className="border flex-wrap pt-3 list-row align-items-center mb-2 dash-box">
                                                                    <div className="col-xl-12 align-items-center">
                                                                        <div className="list-icons"> {item?.attributes?.first_name && item?.attributes?.first_name[0]?.toUpperCase()}{item?.attributes?.last_name && item?.attributes?.last_name[0]?.toUpperCase()}</div>
                                                                        <div className="info mb-3 mt-3">
                                                                            <h4 className="fs-24 mb-0 text-black text-capitalize">
                                                                                {item?.attributes?.first_name} {item?.attributes?.last_name}

                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>

                                                    )
                                            })
                                            }

                                        </div> */}
                                        <div className='busin-cont-main'>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="contact-secs">
                                                        <h3>Contacts</h3>

                                                    </div>
                                                </div>
                                                {contacts?.data && contacts?.data?.map((item, id) => {
                                                    console.log("contacts ", item);
                                                    if (id < 3)
                                                        return (
                                                            <div className="col-md-3 col-sm-6" key={id} onClick={() => { navigate(`/contact-details/${params?.acc_label}/${item?.attributes?.uuid}`, { state: { contactId: item?.attributes?.uuid, uuid: params?.acc_label?.split("_")[0], details: item } }) }}>
                                                                <a className="cursor-pointer">
                                                                    <div className="border flex-wrap list-row align-items-center mb-2 act-box">
                                                                        <div className="col-xl-12 align-items-center">
                                                                            <img src={profile} alt='user' />
                                                                            <div className="info">
                                                                                <h4>
                                                                                    {item?.attributes?.first_name} {item?.attributes?.last_name}

                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>

                                                        )
                                                })
                                                }
                                                <a className='view-all-link' onClick={() => { navigate(`/view-contacts/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0], } }) }}>
                                                    View All
                                                </a>
                                            </div>
                                        </div>


                                        <div className="col-xl-12  mt-4">
                                            <div className="align-items-center">
                                                <div className="main-trans">
                                                    <h4>Transactions</h4>
                                                    {transaction?.data?.data && transaction?.data?.data?.map((item, id) => {
                                                        return (
                                                            <TransactionTable item={item} accountId={allAccountsData?.data?.id} key={id} />
                                                        )
                                                    })
                                                    }
                                                    <a className='view-all-link' onClick={() => { navigate(`/card-transaction/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0], business_uuid: allAccountsData?.data?.attributes?.business_uuid, accountId: allAccountsData?.data?.id } }) }}>
                                                        View All
                                                    </a>
                                                </div> 
                                                {/* <div className="col-md-6 col-sm-6">
                                                  <div className="trans-box">
                                                      <div className="top">
                                                              <div className="icon">
                                                              <svg
                                                                  width={40}
                                                                  height={31}
                                                                  viewBox="0 0 40 31"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                                              >
                                                                  <rect
                                                                      width={40}
                                                                      height="30.8772"
                                                                      fill="url(#pattern0_290_912)"
                                                                  />
                                                                  <defs>
                                                                      <pattern
                                                                          id="pattern0_290_912"
                                                                          patternContentUnits="objectBoundingBox"
                                                                          width={1}
                                                                          height={1}
                                                                      >
                                                                          <use
                                                                              xlinkHref="#image0_290_912"
                                                                              transform="matrix(0.0215539 0 0 0.0279221 0.0150376 0.0227273)"
                                                                          />
                                                                      </pattern>
                                                                      <image
                                                                          id="image0_290_912"
                                                                          width={45}
                                                                          height={35}
                                                                          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAjCAYAAAAAEIPqAAABmUlEQVR4Ae2YS26DMBCGZxzWXKC5AL1Auus625CH1F6gK3qP7pJDRMrDqy5zgbLqrr1AewGkLMN0DBUFBxmKImQiPonHODPRL3ts7EH4hRaLOzidAn71+HLBLkIQQuJ2K5WB6kbzeQBx/Ay2g7jC3W6JLNhnwS/QFeL40eGbrzVHfH2CLSDeANEws4UIHH6Mcg5f3P33YBk0nb7D3zzzRPFX+gY7yY+8K6CDOAWL84dmMxtXEZXXmVEUnSZ8AJbTyfToRbdFL7otrmCd5i0g7vcPYBn8GV9DbrvRp0db9KLbohfdFlexTp9B47ELrnurt+Nm81bHrww99r8YRdNkMuSD5CvXQ87qIHxYSI7zVX6l/5uLbYI5PQYDdVIvF0IU1PKrim2AWXQcm4bxo6afObYBxvRAKUMeyid+LeYqUQTHo6z0K0OLbULlROTcO/DjcCm/S9DZdTrK2R7YiCqN5U19r6pKY5ZVmvTSc+iwwCULXWdNae1jCLZCJIWa+YnwLkC0Yr0SM9v3fe5x9ZEYgV2kpWfu2KSDmR9nyJRallt0QAAAAABJRU5ErkJggg=="
                                                                      />
                                                                  </defs>
                                                              </svg>
                                                          </div>
                                                          <h3>Peter Parkur</h3>
                                                          <p>Intrabank</p>
                                                          <p>f5gf4fg57das6d7h4s8t74d6</p>
                                                      </div>
                                                      <div className="bot">
                                                          <strong>$18.00</strong>
                                                          <span>Completion</span>
                                                          <span>08 May 2024</span>
                                                      </div>
                                                  </div>
                                              </div> */}
                                                {/* <div className="tab-links mt-4">
                                                <div className="col-md-12">
                                                    <a className='cursor-pointer' onClick={() => { navigate(`/spending-patterns/${params?.acc_label?.split("_")[0]}`, { state: { uuid: params?.acc_label?.split("_")[0] } }) }}>
                                                        Spending Patterns
                                                        <svg
                                                            width={19}
                                                            height={19}
                                                            viewBox="0 0 19 19"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M17.832 9.49976H1.16537"
                                                                stroke="black"
                                                                strokeWidth="1.375"
                                                                strokeMiterlimit={10}
                                                                strokeLinecap="round"
                                                            />
                                                            <path
                                                                d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                stroke="black"
                                                                strokeWidth="1.375"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                                <div className="col-xl-12 mt-4">
                                                    <div className="view-all-row">
                                                        <h5>Rewards</h5>
                                                        <a href="#">
                                                            View All{" "}
                                                            <svg
                                                                width={12}
                                                                height={12}
                                                                viewBox="0 0 12 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M10.832 6.30811H0.832031"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M6.43607 1.52051L10.5442 5.62738C10.6341 5.71632 10.7056 5.82221 10.7543 5.93893C10.803 6.05566 10.8281 6.18089 10.8281 6.30738C10.8281 6.43387 10.803 6.55911 10.7543 6.67583C10.7056 6.79256 10.6341 6.89845 10.5442 6.98738L6.43607 11.0955"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="tab-links">
                                                    <div className="col-md-12">
                                                        <a className="cursor-pointer">
                                                            View Rewards
                                                            <svg
                                                                width={19}
                                                                height={19}
                                                                viewBox="0 0 19 19"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.832 9.49976H1.16537"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeMiterlimit={10}
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                    stroke="black"
                                                                    strokeWidth="1.375"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main >
            </div >}

            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading()}
            />
        </>
    )
}

export default ViewAccount
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SecondForm = ({ handlePrev, handleNext, initialFormData, businessData, setBusinessData,data }) => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({
        beneficiaryFirstName: '',
        beneficiaryLastName: '',
        ownerTitle: '',
        driversLicenseNumber: '',
        percentOwnership: ''
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (initialFormData) {
            setFormData(prevData => ({
                ...prevData,
                ...initialFormData
            }));
        }
    }, [initialFormData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'percentOwnership' ) {
            if (value > 100){
                setFormData(prevData => ({ ...prevData, [name]: 100 }));
            }
            else if (value < 0){
                setFormData(prevData => ({ ...prevData, [name]: 0 }));
            }
            else{
                setFormData(prevData => ({ ...prevData, [name]: value }));
                
            }
        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
          
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.beneficiaryFirstName) {
            newErrors.beneficiaryFirstName = 'Beneficiary First Name is required';
        }
        if (!formData.beneficiaryLastName) {
            newErrors.beneficiaryLastName = 'Beneficiary Last Name is required';
        }
        // if (!formData.ownerTitle) {
        //     newErrors.ownerTitle = 'Owner Title is required';
        // }
        if (formData.percentOwnership && isNaN(formData.percentOwnership)) {
            newErrors.percentOwnership = 'Percent Ownership must be a number';
        } 
       else if (!formData.percentOwnership ) {
            newErrors.percentOwnership = 'Percent Ownership required';
        } 

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSaveAndContinue = () => {
        if (validateForm()) {
            setBusinessData({...businessData,...formData})
            handleNext(formData);
        }
    };

    return (
        <>
            <div className="authincation content-body default-height">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-11 col-xxl-10 m-auto main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-12 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation content-body default-height">
                                            <div className="container h-100">
                                                <div className="row justify-content-center h-100 align-items-center">
                                                    <div className="col-lg-12 col-md-10">
                                                        <form className="mt-4">
                                                            <h4>Beneficiary Details</h4>
                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Beneficiary First Name *</label>
                                                                    <input
                                                                        name='beneficiaryFirstName'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Beneficiary First Name"
                                                                        
                                                                        value={formData.beneficiaryFirstName}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.beneficiaryFirstName && <small className="text-danger">{errors.beneficiaryFirstName}</small>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Beneficiary Last Name</label>
                                                                    <input
                                                                        name='beneficiaryLastName'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Beneficiary Last Name"
                                                                        
                                                                        value={formData.beneficiaryLastName}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.beneficiaryLastName && <small className="text-danger">{errors.beneficiaryLastName}</small>}
                                                                </div>
                                                            </div>

                                                            {/* <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Owner Title *</label>
                                                                    <select
                                                                        name='ownerTitle'
                                                                        className="form-control"
                                                                        
                                                                        value={formData.ownerTitle}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option value="">Select Owner Title</option>
                                                                        <option value="CEO">CEO</option>
                                                                        <option value="CFO">CFO</option>
                                                                        <option value="COO">COO</option>
                                                                        <option value="President">President</option>
                                                                        <option value="Secretary">Secretary</option>
                                                                        <option value="Treasurer">Treasurer</option>
                                                                        <option value="Vice President">Vice President</option>
                                                                    </select>
                                                                    {errors.ownerTitle && <small className="text-danger">{errors.ownerTitle}</small>}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Drivers License Number</label>
                                                                    <input
                                                                        name='driversLicenseNumber'
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Drivers License Number"
                                                                        
                                                                        value={formData.driversLicenseNumber}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div> */}

                                                            <div className='form-group-row'>
                                                                <div className="form-group">
                                                                    <label className="mb-1 form-label">Percent Ownership</label>
                                                                    <input
                                                                        name='percentOwnership'
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Percent Ownership"
                                                                        value={formData.percentOwnership}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.percentOwnership && <small className="text-danger">{errors.percentOwnership}</small>}
                                                                </div>
                                                            </div>

                                                            <div className='d-flex justify-content-center mt-3 mb-3 gap-2'>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary"
                                                                    onClick={handlePrev}
                                                                >
                                                                    Previous
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary  "
                                                                    onClick={handleSaveAndContinue}
                                                                    disabled={loading}
                                                                >
                                                                    {loading ? 'Loading...' : 'Save and Continue'}
                                                                </button>
                                                                {step === 2 && (
                                                                    <div className={`form-container ${step === 2 ? 'slide-in' : 'slide-out'}`}>
                                                                        <SecondForm initialFormData={formData}  />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

SecondForm.propTypes = {
    handlePrev: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    initialFormData: PropTypes.shape({
        beneficiaryFirstName: PropTypes.string,
        beneficiaryLastName: PropTypes.string,
        ownerTitle: PropTypes.string,
        driversLicenseNumber: PropTypes.string,
        percentOwnership: PropTypes.string,
    }).isRequired
};

export default SecondForm;

import React from 'react'
import MainHeader from '../components/MainHeader'
import endPoints from '../constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import { Bars } from 'react-loader-spinner';
import { useAuth } from '../components/AuthContext';
import Sidebar from '../components/Sidebar';
import Sidedefault from '../components/Sidedefault';

const Cards = () => {
    const location = useLocation();
    const { userRole } = useAuth();
    const params = useParams();

    const navigate = useNavigate();
    const { data: cards, dataLoading: cardsLoading } = useFetch(`${endPoints.GET_CARDS_BY_ID}?account_id=${params?.acc_label?.split("_")[0]}`);

    const handleAddCard = () => {
        navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards/card-type`, { state: { uuid: location?.state?.uuid, business_uuid: location?.state?.business_uuid } })
    }
    return (
        <>
            {/* <MainHeader /> */}
            {!cardsLoading &&
                <div className="s-layout">
                    <Sidedefault />
                    <main className="s-layout__content">
                        <div className="main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            <div className="me-auto pe-3 mb-3">
                                                <h4 className="text-black fs-26 mb-0">Cards</h4>
                                            </div>
                                            {userRole != "view_account" && userRole != "card_only" && <div className="add-card">
                                                <a onClick={handleAddCard} className='cursor-pointer' href='javascript:void(0)'>
                                                    <svg
                                                        width={23}
                                                        height={19}
                                                        viewBox="0 0 23 19"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g clipPath="url(#clip0_274_1471)">
                                                            <path
                                                                d="M1.81018 16.1337C1.29459 16.1337 0.864181 15.9557 0.518957 15.5997C0.172986 15.243 0 14.7988 0 14.2672V1.8201C0 1.28847 0.172986 0.844672 0.518957 0.48871C0.864181 0.131979 1.29459 -0.0463867 1.81018 -0.0463867H18.3653C18.8808 -0.0463867 19.3113 0.131979 19.6565 0.48871C20.0025 0.844672 20.1754 1.28847 20.1754 1.8201V7.10982H1.12086V14.2672C1.12086 14.4444 1.19259 14.6073 1.33606 14.756C1.48028 14.904 1.63832 14.9779 1.81018 14.9779H11.4238V16.1337H1.81018ZM1.12086 4.35459H19.0546V1.8201C19.0546 1.64289 18.9828 1.47993 18.8394 1.33123C18.6952 1.1833 18.5371 1.10933 18.3653 1.10933H1.81018C1.63832 1.10933 1.48028 1.1833 1.33606 1.33123C1.19259 1.47993 1.12086 1.64289 1.12086 1.8201V4.35459ZM18.4942 19.023V15.5558H15.1316V14.4001H18.4942V10.9329H19.615V14.4001H22.9776V15.5558H19.615V19.023H18.4942Z"
                                                                fill="black"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_274_1471">
                                                                <rect width={23} height={19} fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    Add a Card
                                                </a>
                                            </div>}
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="row">
                                                {cards?.data?.length > 0 && cards?.data?.map((item, id) => {
                                                    return (
                                                        <div className="col-md-4 col-sm-6 cursor-pointer" key={item?.id} onClick={() => { navigate(`/${params?.name?.toLowerCase()}/accounts/${params?.acc_label?.toLowerCase()}/cards/card-section/${item?.attributes?.uuid}`,) }}>
                                                            <div className="flex-wrap align-items-center card-box">
                                                                <div className="col-xl-12 align-items-center">
                                                                    <a
                                                                        className="card-icons cursor-pointer"

                                                                    >
                                                                        <svg
                                                                            width={30}
                                                                            height={24}
                                                                            viewBox="0 0 30 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                        >
                                                                            <rect
                                                                                width={30}
                                                                                height="23.1579"
                                                                                fill="url(#pattern0_293_274)"
                                                                            />
                                                                            <defs>
                                                                                <pattern
                                                                                    id="pattern0_293_274"
                                                                                    patternContentUnits="objectBoundingBox"
                                                                                    width={1}
                                                                                    height={1}
                                                                                >
                                                                                    <use
                                                                                        xlinkHref="#image0_293_274"
                                                                                        transform="matrix(0.0215539 0 0 0.0279221 0.0150376 0.0227273)"
                                                                                    />
                                                                                </pattern>
                                                                                <image
                                                                                    id="image0_293_274"
                                                                                    width={45}
                                                                                    height={35}
                                                                                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAjCAYAAAAAEIPqAAABmUlEQVR4Ae2YS26DMBCGZxzWXKC5AL1Auus625CH1F6gK3qP7pJDRMrDqy5zgbLqrr1AewGkLMN0DBUFBxmKImQiPonHODPRL3ts7EH4hRaLOzidAn71+HLBLkIQQuJ2K5WB6kbzeQBx/Ay2g7jC3W6JLNhnwS/QFeL40eGbrzVHfH2CLSDeANEws4UIHH6Mcg5f3P33YBk0nb7D3zzzRPFX+gY7yY+8K6CDOAWL84dmMxtXEZXXmVEUnSZ8AJbTyfToRbdFL7otrmCd5i0g7vcPYBn8GV9DbrvRp0db9KLbohfdFlexTp9B47ELrnurt+Nm81bHrww99r8YRdNkMuSD5CvXQ87qIHxYSI7zVX6l/5uLbYI5PQYDdVIvF0IU1PKrim2AWXQcm4bxo6afObYBxvRAKUMeyid+LeYqUQTHo6z0K0OLbULlROTcO/DjcCm/S9DZdTrK2R7YiCqN5U19r6pKY5ZVmvTSc+iwwCULXWdNae1jCLZCJIWa+YnwLkC0Yr0SM9v3fe5x9ZEYgV2kpWfu2KSDmR9nyJRallt0QAAAAABJRU5ErkJggg=="
                                                                                />
                                                                            </defs>
                                                                        </svg>
                                                                    </a>
                                                                    <div className="list-icons text-capitalize">{item?.attributes?.card_holder_name[0]}</div>
                                                                    <div className="info mb-3 mt-3">
                                                                        <h4 className="fs-20 mb-0 text-black">
                                                                            {item?.attributes?.card_holder_name}
                                                                        </h4>
                                                                        <span>xxxx-{item?.attributes?.lastFour}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                                }
                                                {cards?.data?.length == 0 && <div className="">No Cards </div>}

                                                {/* <div className="col-md-12">
                                                  <nav aria-label="..." className="d-flex mt-3 mb-3">
                                                      <ul className="pagination pagination-circle m-auto">
                                                          <li className="page-item">
                                                              <a className="page-link">
                                                                  <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width={15}
                                                                      height={15}
                                                                      viewBox="0 0 16 16"
                                                                  >
                                                                      <path
                                                                          fill="currentColor"
                                                                          d="M10.843 13.069L6.232 8.384a.546.546 0 0 1 0-.768l4.61-4.685a.552.552 0 0 0 0-.771a.53.53 0 0 0-.759 0l-4.61 4.684a1.65 1.65 0 0 0 0 2.312l4.61 4.684a.53.53 0 0 0 .76 0a.552.552 0 0 0 0-.771"
                                                                      />
                                                                  </svg>{" "}
                                                                  Prev
                                                              </a>
                                                          </li>
                                                          <li className="page-item">
                                                              <a className="page-link" href="#">
                                                                  1
                                                              </a>
                                                          </li>
                                                          <li className="page-item active" aria-current="page">
                                                              <a className="page-link" href="#">
                                                                  2 <span className="visually-hidden">(current)</span>
                                                              </a>
                                                          </li>
                                                          <li className="page-item">
                                                              <a className="page-link" href="#">
                                                                  3
                                                              </a>
                                                          </li>
                                                          <li className="page-item">
                                                              <a className="page-link" href="#">
                                                                  Next{" "}
                                                                  <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width={17}
                                                                      height={17}
                                                                      viewBox="0 0 7 16"
                                                                  >
                                                                      <path
                                                                          fill="currentColor"
                                                                          d="M1.5 13a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71L5.3 7.99L1.15 3.85c-.2-.2-.2-.51 0-.71c.2-.2.51-.2.71 0l4.49 4.51c.2.2.2.51 0 .71l-4.5 4.49c-.1.1-.23.15-.35.15Z"
                                                                      />
                                                                  </svg>
                                                              </a>
                                                          </li>
                                                      </ul>
                                                  </nav>
                                              </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={cardsLoading}
            />

        </>
    )
}

export default Cards

// import React, { createContext, useContext, useState } from "react";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [userRole, setUserRole] = useState("admin"); // Example, you should get this from your auth logic

//   return (
//     <AuthContext.Provider value={{ userRole, setUserRole }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);

// import React, { createContext, useContext, useState, useEffect } from "react";
// import { decryptData } from "../hooks/encryptionUtils"; // Import your decryption utility

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [userRole, setUserRole] = useState(null); // Initially null until we fetch the role

//   useEffect(() => {
//     // Function to retrieve and decrypt the role from localStorage
//     const fetchUserRole = () => {
//       let encryptedRole = localStorage.getItem("skyrole");
//       if (encryptedRole) {
//         try {
//           const decryptedRole = decryptData(encryptedRole);
//           setUserRole(decryptedRole);
//         } catch (error) {
//           console.error("Decryption error:", error.message);
//         }
//       }
//     };

//     fetchUserRole();
//   }, []);

// //   const updateRole = (newRole) => {
// //     // Encrypt the role before storing it
// //     const encryptedRole = encryptData(newRole);
// //     localStorage.setItem("skyrole", encryptedRole);
// //     setUserRole(newRole);
// //   };

//   return (
//     <AuthContext.Provider value={{ userRole }}>
//     {/* <AuthContext.Provider value={{ userRole, setUserRole: updateRole }}> */}
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);

import React, { createContext, useContext, useState, useEffect } from "react";
import { decryptData } from "../hooks/encryptionUtils"; // Import your decryption utility
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate=useNavigate();
  const [userRole, setUserRole] = useState(null); // Initially null until we fetch the role
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    // Function to retrieve and decrypt the role from localStorage
    const fetchUserRole = () => {
      let encryptedRole = localStorage.getItem("skyrole");
      if (encryptedRole) {
        try {
          const decryptedRole = decryptData(encryptedRole);
          setUserRole(decryptedRole);
        } catch (error) {
          console.error("Decryption error:", error.message);
           localStorage.clear();
           navigate("/login");
        }
      }
      setLoading(false); // Set loading to false after fetching the role
    };

    fetchUserRole();
  }, []);

  return (
    <AuthContext.Provider value={{ userRole, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);


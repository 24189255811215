import React, { useContext, useEffect, useState } from 'react'
import './otp.css';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import { toast } from 'react-toastify';
import endPoints from '../constants';
import { Bars } from 'react-loader-spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { MyContext } from '../hooks/MyProvider';
import SimpleHeader from '../components/SimpleHeader';

const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const { phone } = useContext(MyContext);
  console.log("phone", phone);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const request = useLocation();
  const [requestState, setRequestState] = useState(null);

  useEffect(() => {
    if (request.state) {
      setRequestState(request.state);
    }
  }, [request]);
  const user = localStorage.getItem("skyfi");
  const tk = JSON.parse(user);
  const handleOtpChange = (otpValue) => {
    console.log(otpValue)
    if (/^[0-9]*$/.test(otpValue)) {
      setOtp(otpValue);
    } else {
      setOtp(otpValue)
    }
  };
  console.log("requestState", requestState);

  const handleVerify = async (e) => {
    e.preventDefault();

    if (otp.length != 4) {
      toast.error("Invalid Code");
      return;
    }

    let data = {
      request_id: requestState,
      code: otp
    }
    try {
      setLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.VERIFY_CODE}`, data, {
        headers: {
          'Authorization': `Bearer ${tk.access_token}`
        }
      });
      setLoading(false);
      if (resp?.status === 200) {
        toast.success("MFA Verified Successfully");
        navigate("/identity-verification", { state: true });
      }

    } catch (error) {
      setLoading(false);
      toast.error(`${error.response.data.error}`);
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MFA_VERIFY}`, { country_code: phone?.countryCode }, {
        headers: {
          'Authorization': `Bearer ${tk.access_token}`
        }
      });
      setLoading(false);
      console.log("resp", resp);
      if (resp?.status === 200) {
        setRequestState(resp?.data?.request_id);
        toast.success("Code resend Successfully");
      }

    } catch (error) {
      setLoading(false);
      toast.error(`${error.response.data.error}`);
    }
  }

  const formatPhone = (phone) => {
    if (phone) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    return phone;
  }

  return (
    <>
      <SimpleHeader />
      <div className="row">
        <div className="otp-box">
          <div className="bg-white p-5 rounded-4 shadow-sm border">
            <div>
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 1024 1024">
                  <path fill="currentColor" d="M800 384h-32V261.872C768 115.024 661.744 0 510.816 0C359.28 0 256 117.472 256 261.872V384h-32c-70.592 0-128 57.408-128 128v384c0 70.592 57.408 128 128 128h576c70.592 0 128-57.408 128-128V512c0-70.592-57.408-128-128-128zM320 261.872C320 152.784 394.56 64 510.816 64C625.872 64 704 150.912 704 261.872V384H320V261.872zM864.001 896c0 35.28-28.72 64-64 64h-576c-35.28 0-64-28.72-64-64V512c0-35.28 28.72-64 64-64h576c35.28 0 64 28.72 64 64v384zm-352-320c-35.344 0-64 28.656-64 64c0 23.632 12.96 44.032 32 55.12V800c0 17.664 14.336 32 32 32s32-14.336 32-32V695.12c19.04-11.088 32-31.504 32-55.12c0-35.344-28.656-64-64-64z" />
                </svg>
              </div>
              <h5>Enter Verification Code</h5>
              <p>A verification code has been sent to:</p>
              <div className="otp-phone">
                {formatPhone(phone?.phone)}
              </div>
              <form className="row">
                <p className="text-start">Authentication Code</p>
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} className='otp-letter-input' placeholder=' ' />}
                />
              </form>
              <div className="row button-sec">
                <div className="col-12">
                  <div className="text-muted text-center">
                    <a href="#" onClick={handleResend}>Resend Code</a>
                  </div>
                  <button className="btn" onClick={handleVerify}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={loading}
      />
    </>
  )
}

export default OtpVerification

// Pagination.js
import React, { memo, useState } from 'react';

const Pagination = ({ totalPages, onPageChange,current }) => {
    const [currentPage, setCurrentPage] = useState(current);

    const handlePrevious = (e) => {
        e.preventDefault();
        const newPage = Math.max(currentPage - 1, 1);
        setCurrentPage(newPage);
        onPageChange(newPage);
    };

    const handleNext = (e) => {
        e.preventDefault();
        const newPage = Math.min(currentPage + 1, totalPages);
        setCurrentPage(newPage);
        onPageChange(newPage);
    };

    const handlePageClick = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };

    const getPageNumbers = () => {
        let startPage = Math.max(currentPage - 1, 1);
        let endPage = Math.min(startPage + 2, totalPages);

        if (endPage - startPage < 2) {
            startPage = Math.max(endPage - 2, 1);
        }

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li
                    key={i}
                    className={`page-item ${currentPage === i ? 'active' : ''}`}
                >
                    <a className="page-link cursor-pointer" onClick={(e) => handlePageClick(e, i)}>
                        {i}
                    </a>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <div className="col-md-12">
            <nav aria-label="..." className="d-flex mt-3 mb-3">
                <ul className="pagination pagination-circle m-auto">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a className="page-link cursor-pointer" onClick={handlePrevious}>
                            Previous
                        </a>
                    </li>
                    {getPageNumbers()}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <a className="page-link cursor-pointer" onClick={handleNext}>
                            Next
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default memo(Pagination);

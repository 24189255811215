// // src/encryptionUtils.js
// import CryptoJS from "crypto-js";

// const SECRET_KEY = process.env.REACT_APP_ENCRYPT_KEY;

// export const encryptData = (data) => {
//   console.log("encrtyo============", SECRET_KEY);
//   // Convert JSON object to string before encryption
//   const dataString = JSON.stringify(data);
//   // Encrypt the string
//   const encrypted = CryptoJS.AES.encrypt(dataString, SECRET_KEY).toString();
//   return encrypted;
// };

// export const decryptData = (data) => {
//   // Decrypt the string
//   const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
//   // Convert decrypted bytes back to string
//   const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
//   // Parse string back to JSON object
//   console.log("decryped striong", decryptedString);
//   return JSON.parse(decryptedString);
// };

// src/encryptionUtils.js
import CryptoJS from "crypto-js";
import { Navigate } from "react-router-dom";

const SECRET_KEY = process.env.REACT_APP_ENCRYPT_KEY;

export const encryptData = (data) => {
  try {
    // Ensure SECRET_KEY is available
    if (!SECRET_KEY) {
      throw new Error("Encryption key is not defined.");
    }

    // Convert JSON object to string before encryption
    const dataString = JSON.stringify(data);

    // Encrypt the string
    const encrypted = CryptoJS.AES.encrypt(dataString, SECRET_KEY).toString();

    return encrypted;
  } catch (error) {
    console.error("Encryption error:", error);
    throw error;
  }
};

export const decryptData = (data) => {
  try {
    // Ensure SECRET_KEY is available
    if (!SECRET_KEY) {
      throw new Error("Decryption key is not defined.");
    }

    // Decrypt the string
    const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);

    // Convert decrypted bytes back to string
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

    // Check if the decrypted string is valid JSON
    try {
      return JSON.parse(decryptedString);
    } catch (parseError) {
      throw new Error("Decryption failed, invalid JSON data.");
    }
  } catch (error) {
    console.error("Decryption error:", error);

     localStorage.clear();
     Navigate("/login",{replace:true})
    
    throw error;
  }
};

import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import { useScrollToTop } from '../hooks/useScrollToTop'

const SetPin = () => {
// useScrollToTop();
const [pin,setPin]=useState({
    
})

  return (
  <>
  <MainHeader />
          <div className="authincation content-body default-height">
              <div className="container h-100">
                  <div className="row justify-content-center h-100 align-items-center">
                      <div className="col-xl-11 col-xxl-10 m-auto main-page">
                          <div className="row justify-content-center h-100 align-items-center">
                              <div className="col-lg-8 col-md-10">
                                  <div className="card login-part">
                                      <div className="authincation-content">
                                          <div className="row no-gutters">
                                              <div className="col-xl-12">
                                                  <div className="auth-form">
                                                      <h4 className="mb-4">Set 4-digit PIN</h4>
                                                      <form action="#" className="mt-4">
                                                          <div className="form-group">
                                                              <label className="mb-1 form-label">
                                                                  {" "}
                                                                  4-digit PIN
                                                              </label>
                                                              <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Enter 4-digit PIN"
                                                              />
                                                          </div>
                                                          <div className="form-group">
                                                              <label className="mb-1 form-label">
                                                                  {" "}
                                                                  Re-Enter 4-digit PIN
                                                              </label>
                                                              <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Re-Enter 4-digit PIN"
                                                              />
                                                          </div>
                                                          <div className="text-center">
                                                              <button type="submit" className="btn">
                                                                  Submit
                                                              </button>
                                                          </div>
                                                      </form>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

  </>
  )
}

export default SetPin
import React, { useEffect, useState } from 'react';
import MainHeader from '../components/MainHeader';
import SecondForm from './SecondForm';
import './RollFI.css';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import useFetch from '../hooks/useFetch';
import { useParams } from 'react-router-dom';
import endPoints from '../constants';
import { Bars } from 'react-loader-spinner';
import Breadcrumb from '../components/Breadcrumb';

const FirstForm = ({ handleNext, setBusinessData, data, dataLoading, routes }) => {
    const params = useParams();

    const [addressInput, setAddressInput] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        personalEmail: '',
        phoneNumber: '',
        dateOfBirth: '',
        addressInput: '',
    });
    const [contactAddress, setContactAddress] = useState({
        address1: '',
        state: '',
        city: '',
        country: '',
        zipCode: '',
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);

    const formatPhoneNumber = (value) => {
        if (!value) return '';
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            return [match[1], match[2], match[3]].filter(Boolean).join('-');
        }
        return value;
    };



    useEffect(() => {
        if (data) {
            setFormData((pre) => ({
                ...pre,
                firstName: '',
                lastName: '',
                personalEmail: data?.email,
                phoneNumber: data?.phone,
                dateOfBirth: '',
                addressInput: '',
            }))
            setContactAddress((pre) => ({
                ...pre,
                address1: data?.address_1,
                country: data?.country,
                zipCode: data?.zip_code,
                state: data?.state,
                city: data?.city,

            }))
            setAddressInput(data?.address_1)
        }
    }, [data])
    const handleSelect = async (address) => {
        setAddressInput(address);
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;

            const newAddress = {
                address1: address,
                state: '',
                city: '',
                country: '',
                zipCode: ''
            };

            addressComponents.forEach(component => {
                const types = component.types;
                if (types.includes('locality')) {
                    newAddress.city = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.state = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode = component.long_name;
                }
                if (types.includes('country')) {
                    newAddress.country = component.long_name;
                }
            });

            setContactAddress(newAddress);
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    const phoneRegex = /^\d{10}$/;

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name in formData) {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
        else if (name in contactAddress) {
            setContactAddress(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
        else if (name === 'addressInput') {
            setAddressInput(value);
        }

        if (name === 'phoneNumber') {
            const formattedValue = value.replace(/\D/g, '');
            setFormData(prevData => ({ ...prevData, [name]: formattedValue }));

            if (formattedValue.length === 10 && phoneRegex.test(formattedValue)) {
                setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
            } else {
                setErrors(prevErrors => ({ ...prevErrors, [name]: 'Phone number must be 10 digits.' }));
            }
        }
    };

    const validateForm = () => {
        const newErrors = {};
        // if (!formData.firstName) newErrors.firstName = "First Name is required";
        // if (!formData.lastName) newErrors.lastName = "Last Name is required";
        if (!formData.personalEmail) newErrors.personalEmail = "Personal Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.personalEmail)) newErrors.personalEmail = "Email is invalid";
        if (!formData.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
        else if (!/^\d{10}$/.test(formData.phoneNumber)) newErrors.phoneNumber = "Phone Number is invalid";
        if (!formData.dateOfBirth) newErrors.dateOfBirth = "Date of Birth is required";
        if (!addressInput) newErrors.personalAddress = "Address is required";

        if (!contactAddress.state) newErrors.state = "State is required";
        if (!contactAddress.city) newErrors.city = "City is required";
        if (!contactAddress.country) newErrors.country = "Country is required";
        if (!contactAddress.zipCode) newErrors.zipCode = "Zip Code is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNexts = () => {
        if (validateForm()) {
            // console.log('Form Data before submission:', formData);
            setLoading(true);
            setBusinessData({ ...formData, contactAddress })

            setFormData(prevData => ({
                ...prevData,
                address: contactAddress
            }));
            handleNext();
        }
    };

    return (
        <>
            <MainHeader />
            {!dataLoading && <div className="authincation content-body default-height">
                <div className="container h-100">  
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-11 col-xxl-10 m-auto main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-12 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div>
                                                        <form className="mt-4">
                                                            <div className={`form-container ${step === 0 ? 'slide-in' : 'slide-out'}`}>
                                                                <h4>Personal Details</h4>
                                                                <p>Tell us about you</p>
                                                                {/* <div className='form-group-row'>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">First Name *</label>
                                                                        <input
                                                                            name='firstName'
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="First Name"
                                                                            
                                                                            value={formData.firstName}
                                                                            onChange={handleChange}
                                                                        />
                                                                        {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">Last Name *</label>
                                                                        <input
                                                                            name='lastName'
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Last Name"
                                                                            
                                                                            value={formData.lastName}
                                                                            onChange={handleChange}
                                                                        />
                                                                        {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
                                                                    </div>
                                                                </div> */}
                                                                <div className='form-group-row'>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">Personal Email</label>
                                                                        <input
                                                                            name='personalEmail'
                                                                            type="email"
                                                                            className="form-control"
                                                                            placeholder="Personal Email"

                                                                            value={formData.personalEmail}
                                                                            onChange={handleChange}
                                                                        />
                                                                        {errors.personalEmail && <small className="text-danger">{errors.personalEmail}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">Phone Number *</label>
                                                                        <input
                                                                            name='phoneNumber'
                                                                            type="tel"
                                                                            className="form-control"
                                                                            placeholder="Phone Number"

                                                                            value={formatPhoneNumber(formData.phoneNumber)}
                                                                            onChange={handleChange}
                                                                        />
                                                                        {errors.phoneNumber && <small className="text-danger">{errors.phoneNumber}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className='form-group-row'>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">Date Of Birth</label>
                                                                        <input
                                                                            name='dateOfBirth'
                                                                            type="date"
                                                                            className="form-control"
                                                                            placeholder="Date Of Birth"

                                                                            value={formData.dateOfBirth}
                                                                            onChange={handleChange}
                                                                        />
                                                                        {errors.dateOfBirth && <small className="text-danger">{errors.dateOfBirth}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">Personal Address</label>
                                                                        <PlacesAutocomplete
                                                                            value={addressInput}
                                                                            onChange={setAddressInput}
                                                                            onSelect={handleSelect}
                                                                        >
                                                                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                                                <div>
                                                                                    <input
                                                                                        {...getInputProps({
                                                                                            placeholder: "Address",
                                                                                            className: "form-control",

                                                                                        })}
                                                                                    />
                                                                                    <div>
                                                                                        {suggestions.map(suggestion => (
                                                                                            <div {...getSuggestionItemProps(suggestion)}>
                                                                                                {suggestion.description}
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </PlacesAutocomplete>
                                                                        {errors.personalAddress && <small className="text-danger">{errors.personalAddress}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className='form-group-row'>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">State</label>
                                                                        <input
                                                                            name='state'
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="State"
                                                                            value={contactAddress.state}
                                                                            onChange={handleChange}

                                                                        />
                                                                        {errors.state && <small className="text-danger">{errors.state}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">City</label>
                                                                        <input
                                                                            name='city'
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="City"
                                                                            value={contactAddress.city}
                                                                            onChange={handleChange}

                                                                        />
                                                                        {errors.city && <small className="text-danger">{errors.city}</small>}
                                                                    </div>
                                                                </div>
                                                                <div className='form-group-row'>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">Country</label>
                                                                        <input
                                                                            name='country'
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Country"
                                                                            value={contactAddress.country}
                                                                            onChange={handleChange}

                                                                        />
                                                                        {errors.country && <small className="text-danger">{errors.country}</small>}
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="mb-1 form-label">Zip Code</label>
                                                                        <input
                                                                            name='zipCode'
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Zip Code"
                                                                            value={contactAddress.zipCode}
                                                                            onChange={handleChange}

                                                                        />
                                                                        {errors.zipCode && <small className="text-danger">{errors.zipCode}</small>}
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary mt-4"
                                                                    onClick={handleNexts}
                                                                    disabled={loading}
                                                                >
                                                                    {loading ? 'Loading...' : 'Save and Continue'}
                                                                </button>
                                                            </div>
                                                            {step === 1 && (
                                                                <div className={`form-container ${step === 1 ? 'slide-in' : 'slide-out'}`}>
                                                                    <SecondForm initialFormData={formData} />
                                                                </div>
                                                            )}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={dataLoading}
            />
        </>
    );
};

export default FirstForm;

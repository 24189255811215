import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';
import MainHeader from '../components/MainHeader';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import endPoints from '../constants';
import useFetch from '../hooks/useFetch';
import { CreateBusiness } from './CreateBusiness';
import BusinessAddress from './BusinessAddress';
import useMutation from '../hooks/useMutation';
import Sidebar from '../components/Sidebar';
import Sidedefault from '../components/Sidedefault';

const BusinessFormType = {
  businessDetails: "Business-Details",
  businessAddress: "Business-Address"
}

const BusinessForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [state, setState] = useState({
    currentForm: BusinessFormType.businessDetails,
    editable: false,
  })

  const { data: businessDetails, loading, mutate: fetchBusinessDetails } = useMutation(`${endPoints.ALL_BUSINESS}/${params?.b_id}`)

  // Fetch business data
  useEffect(() => {
    if (businessDetails) {
      businessDetails.account = ""
    }
    if (window.location.pathname.includes("update-business") && !businessDetails) {
      fetchBusinessDetails();
    }
    else {
      setState((prev) => ({
        ...prev,
        editable: true,
      }));
    }
  }, [window.location.pathname]);



  return (
    <>
      {/* <MainHeader /> */}
      <div className="s-layout">
        <Sidedefault />
        <main className="s-layout__content">
          <div className="main-page">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-lg-8 col-md-10">
                <div className="card login-part">
                  <div className="authincation-content">
                    <div className="row no-gutters">
                      <div className="col-xl-12">
                        <div className="auth-form">
                          <div className='d-flex justify-content-between'>
                            <h4 className="mb-4">
                              {window?.location?.pathname?.includes("update-business") ? "Update Business" : "Create New Business"}
                            </h4>
                            {!state.editable && !window?.location?.pathname?.includes("create-business") && (
                              <button type="button" className="btn" onClick={() => {
                                setState((prev) => ({
                                  ...prev,
                                  editable: true,
                                }));
                              }}>
                                Request Changes
                              </button>
                            )}
                          </div>
                          <div className="form-container abd">
                            {state.currentForm === BusinessFormType.businessDetails && (
                              <div className={`form-content show`}>
                                <CreateBusiness business={businessDetails} editable={state.editable} onNextClick={() => {
                                  setState((state) => ({
                                    ...state,
                                    currentForm: BusinessFormType.businessAddress
                                  }));
                                }} />
                              </div>
                            )}
                            {state.currentForm === BusinessFormType.businessAddress && (
                              <div className={`form-content show`}>
                                <BusinessAddress business={businessDetails} editable={state.editable} onPreviousClick={() => {
                                  setState((state) => ({
                                    ...state,
                                    currentForm: BusinessFormType.businessDetails
                                  }));
                                }} />
                              </div>
                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Bars
        height="80"
        width="80"
        color="#39AFFF"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars"
        visible={loading}
      />
    </>
  );
};

export default BusinessForm;

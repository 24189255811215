import React, { useContext, useEffect, useState } from 'react';
import MainHeader from '../components/MainHeader';
import axios from 'axios';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import baseUrl from '../baseUrl';
import endPoints from '../constants';
import { toast } from 'react-toastify';
import useFetch from '../hooks/useFetch';
import { MyContext } from '../hooks/MyProvider';
import { Bars } from 'react-loader-spinner';

const BusinessAddress = ({ business, editable, onPreviousClick }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { updatebusiness, phone } = useContext(MyContext);
    const [loading, setLoading] = useState(false);
    const [fetchBusiness, setFetchBusiness] = useState(0);

    const [businessAddress, setBusinessAddress] = useState({
        address1: business?.address_1 ?? "",
        address2: business?.address_2 ?? "",
        city: business?.city ?? "",
        regionCode: business?.state ?? "",
        zipCode: business?.zip_code ?? "",

    });
    const [errors, setErrors] = useState({
        address1: "",
    });
    const [addressInput, setAddressInput] = useState('');


    const handleChange = (e) => {
        const { value, name } = e.target;
        setBusinessAddress({ ...businessAddress, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleSelect = async (address) => {

        // setAddressInput(address);
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;

            const newAddress = {
                address1: address,
                address2: '',
                city: '',
                regionCode: '',
                zipCode: ''
            };

            addressComponents.forEach(component => {
                const types = component.types;
                if (types.includes('locality')) {
                    newAddress.city = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.regionCode = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode = component.long_name;
                }
            });

            setBusinessAddress(newAddress);
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };
    const createNewBusiness = async () => {


        // const defaultBusinessDetails = {
        //     name: '',
        //     entity_type: '',
        //     ein_number: '',
        //     estDate: '',
        //     website: '',
        //     address: {
        //         street1: '',
        //         street2: '',
        //         city: '',
        //         region_code: '',
        //         postal_code: '',
        //         country_code: "US"
        //     }
        // };

        // const businessDetails =  defaultBusinessDetails;

        let business = {
            name: updatebusiness?.businessName || '',
            entity_type: updatebusiness?.entityType || '',
            ein_number: updatebusiness?.ein_number || '',
            estDate: updatebusiness?.ESTDate || '',
            website: updatebusiness?.webURL || '',
            address: {
                street1: businessAddress.address1 || '',
                street2: businessAddress.address2 || '',
                city: businessAddress.city || '',
                region_code: businessAddress.regionCode || '',
                postal_code: businessAddress.zipCode || '',
                country_code: "US"
            }
        };


        const user = localStorage.getItem("skyfi");
        const tk = JSON.parse(user);

        try {
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.CREATE_BUSINESS}`, { business: business }, {
                headers: {
                    'Authorization': `Bearer ${tk.access_token}`
                }
            });

            if (resp.status === 200) {
                toast.success("Business Created");
                setBusinessAddress({
                    address1: "",
                    address2: "",
                    city: "",
                    regionCode: "",
                    zipCode: "",
                });
                if (location?.pathname === "/register-business-details") {
                    try {
                        setLoading(true);
                        const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.MFA_VERIFY}`, { country_code: phone?.countryCode }, {
                            headers: {
                                'Authorization': `Bearer ${tk.access_token}`
                            }
                        });
                        setLoading(false);
                        if (resp?.status === 200) {
                            navigate("/otp-verify", { state: resp?.data?.request_id });
                        }

                    } catch (error) {
                        setLoading(false);
                        toast.error(`${error.response.data.error}`);
                    }

                } else {
                    navigate("/");
                }
            }
        } catch (error) {
            toast.error(`${error.response.data.error}`);
        } finally {
            setLoading(false);
        }
    };


    const updateBusiness = async () => {
        try {
            let business = {
                legal_name: updatebusiness?.businessName,
                entity_type: updatebusiness?.entityType,
                // ein_number: location?.state?.businessDetail?.ein_number,
                formation_date: updatebusiness?.ESTDate,
                website: updatebusiness?.webURL,

                address_1: businessAddress?.address1,
                address_2: businessAddress.address2,
                city: businessAddress.city,
                state: businessAddress.regionCode,
                zip_code: businessAddress.zipCode,
                country: "US"

            }

            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);

            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.ALL_BUSINESS}/${params?.b_id}/request_changes_for_business`, business,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {
                toast.success(resp.data.message);
                setBusinessAddress({
                    address1: "",
                    address2: "",
                    city: "",
                    regionCode: "",
                    zipCode: "",
                });
                navigate("/");
            }
            setLoading(false);

        } catch (error) {

            console.log(error);

        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        let isError = false;
        const newErrors = { ...errors };
        for (const key in businessAddress) {
            if (businessAddress.hasOwnProperty(key) && key !== 'BDA' && key !== 'address2') {
                if (businessAddress[key].trim() === "") {
                    newErrors[key] = `Required`;
                    isError = true;
                }
            }
        }
        if (isError) {
            setErrors(newErrors);
            return;
        }

        try {
            setLoading(true);
            if (window?.location?.pathname?.includes("update-business")) {
                updateBusiness();
            } else {
                createNewBusiness();

            }
            // let business = {
            //     name: location?.state?.businessDetail?.businessName,
            //     entity_type: location?.state?.businessDetail?.entityType,
            //     ein_number: location?.state?.businessDetail?.ein_number,
            //     estDate: location?.state?.businessDetail?.ESTDate,
            //     website: location?.state?.businessDetail?.webURL,
            //     address: {
            //         street1: businessAddress.address1,
            //         street2: businessAddress.address2,
            //         city: businessAddress.city,
            //         region_code: businessAddress.regionCode,
            //         postal_code: businessAddress.zipCode,
            //         country_code: "US"
            //     }
            // }

            // const user = localStorage.getItem("skyfi");
            // const tk = JSON.parse(user);
            // const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.CREATE_BUSINESS}`, { business: business },
            //     {
            //         headers: {
            //             'Authorization': `Bearer ${tk.access_token}`
            //         }
            //     }
            // );
            // if (resp.status == 200) {
            //     toast.success("Business Created");
            //     setBusinessAddress  ({
            //         address1: "",
            //         address2: "",
            //         city: "",
            //         regionCode: "",
            //         zipCode: "",
            //     });
            //     navigate("/");
            // }
            // setLoading(false);

        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.error);
            console.log("Error:", error);
        }
    };


    return (
        <>
            <form className="mt-4" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="mb-1 form-label">Address 1</label>
                    <PlacesAutocomplete
                        value={businessAddress.address1}
                        onChange={(value) => {
                            setBusinessAddress((prev) => {
                                return {
                                    ...prev,
                                    address1: value
                                }
                            })
                        }}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input

                                    {...getInputProps({
                                        placeholder: 'Enter Address 1',
                                        className: 'form-control',
                                        name: 'address1',
                                        disabled: !editable
                                    })}
                                />
                                {errors.address1 && <small className="text-danger">{errors.address1}</small>}
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, idd) => {
                                        const className = "cursor-pointer"
                                        const style = {
                                            backgroundColor: suggestion.active
                                                ? "#41B6E6"
                                                : "#fff",
                                            border: suggestion.active
                                                ? "1px solid #41B6E6"
                                                : "1px solid #ccc",
                                            width: "410px",
                                            marginLeft: "10px",
                                            marginTop: "5px",

                                        };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span key={idd} className=''>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>
                <div className="form-group">
                    <label className="mb-1 form-label">Address 2</label>
                    <textarea
                        className="form-control"
                        rows={2}
                        placeholder="Enter Address 2"
                        spellCheck="false"
                        name="address2"
                        value={businessAddress.address2}
                        onChange={handleChange}
                        disabled={!editable}
                    />
                </div>
                <div className="form-group">
                    <label className="mb-1 form-label">City</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter City"
                        name="city"
                        disabled={!editable}
                        value={businessAddress.city}
                        onChange={handleChange}

                    />
                    {errors.city && <small className="text-danger">{errors.city}</small>}
                </div>
                <div className="form-group">
                    <label className="mb-1 form-label">Region Code</label>
                    <input
                        type="text"
                        disabled={!editable}
                        name="regionCode"
                        className="form-control"
                        placeholder="Enter Region Code"
                        value={businessAddress.regionCode}
                        onChange={handleChange}
                    />
                    {errors.regionCode && <small className="text-danger">{errors.regionCode}</small>}
                </div>
                <div className="form-group">
                    <label className="mb-1 form-label">Zip Code</label>
                    <input
                        type="text"
                        name="zipCode"

                        className="form-control"
                        placeholder="Enter Zip Code"
                        value={businessAddress.zipCode}
                        onChange={handleChange}
                        disabled={!editable}
                    />
                    {errors.zipCode && <small className="text-danger">{errors.zipCode}</small>}
                </div>

                <div className="button-container">

                    <button type="button" className="btn" onClick={onPreviousClick}>
                        Previous
                    </button>
                    {editable && (
                        <button type="submit" className="btn" disabled={loading}>
                            {loading ? "Loading" : window?.location?.pathname?.includes("update-business") ? "Update" : "Create"}
                        </button>
                    )}
                    {/* {window?.location?.pathname?.includes("create-business") && (
                        <button type="submit" className="btn" disabled={loading}>
                            {loading ? "Loading" : "Create"}
                        </button>
                    )} */}
                </div>


            </form>
            <Bars
                height="80"
                width="80"
                color="#39AFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={loading}
            />
        </>
    );
};

export default BusinessAddress;



import React from 'react'

const Footer = () => {
  return (
      <footer className="footer">
          <div className="copyright">
              <p>
                  2024 ©&nbsp;{" "}
                  <a className="cursor-pointer" target="_blank">
                      SkyFi
                  </a>
                  . All Rights Reserved
              </p>
          </div>
      </footer>
  )
}

export default Footer
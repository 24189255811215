import React from 'react'
import SimpleHeader from '../components/SimpleHeader'

const PageNotFount = () => {
  return (
    <>
    <SimpleHeader />
    <div>
        <h1 className='text-center'>Page Not Found</h1>
    </div>
    </>
  )
}

export default PageNotFount
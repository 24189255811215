const SardineService = {
  initialize: (sessionKey) => {

    const environment = process.env.REACT_APP_SARDINE_ENVIRONMENT;
    const isSandbox = environment == 'sandbox'
    const sardineHost = isSandbox ? "api.sandbox.sardine.ai" : "api.sardine.ai";
    const loader = document.createElement("script");
    loader.type = "text/javascript";
    loader.async = true;
    loader.src = `https://${sardineHost}/assets/loader.min.js`;

    loader.onload = () => {
      const sardineContextConfig = {
        clientId: process.env.REACT_APP_SARDINE_CLIENT_ID,
        sessionKey: sessionKey,
        flow: "login",
        environment: environment,
        parentElement: document.body,
        onDeviceResponse: (data) => {console.log("sardingesla========",data) },
      }
      window._Sardine.createContext(sardineContextConfig);
    };

    document.body.appendChild(loader);

    // return () => {
    //   // Clean up the script when the component is unmounted
    //   document.head.removeChild(loader);
    // };
  },

  updateConfig: (newConfig) => {
    if (window._Sardine && window._Sardine.updateConfig) {
      window._Sardine.updateConfig(newConfig);
    }
  },
};

export default SardineService;

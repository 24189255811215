import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const usePost = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const post = async (payload) => {
    setLoading(true);
    try {
      
      const user = localStorage.getItem("skyfi");
      const tk = JSON.parse(user);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${url}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${tk.access_token}`,
          },
        }
      );
      if (response?.status == 200) {
        setData(response);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      if (error.response.status == 401) {
        localStorage.clear();
      
        navigate("/login");
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, post };
};

export default usePost;
